<template>
  <div>
    <BaseHeader
      :title="$t('support.title')"
      :style-variant="4"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t(pageLabels[platform].intro) }}</p>
      </template>
    </BaseHeader>

    <Feature :feature-slug="featureNames.ASKAI_ASSISTENT_PREVIEW">
      <v-row>
        <v-col cols="12">
          <AskAi />
        </v-col>
      </v-row>
    </Feature>

    <v-row>
      <Feature
        v-for="(featureSlug, topic) in topics"
        v-slot="{feature}"
        :key="topic"
        :feature-slug="featureSlug"
      >
        <v-col
          cols="12"
          md="6"
          :lg="activeFeaturesCount < 2 ? '6' : '4'"
        >
          <HelpCard
            :topic="topic"
            :config="feature.config"
          />
        </v-col>
      </Feature>

      <v-col
        cols="12"
        md="6"
        :lg="activeFeaturesCount < 2 ? '6' : '4'"
      >
        <Support
          v-if="hasDedicatedSupport"
          class="h-full"
          :company="company"
        />
        <SupportFormCard v-else-if="hasSupport" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HelpCard from '@/modules/helpCenter/HelpCard'
import Support from '@/modules/helpCenter/Support'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import BaseHeader from '@/components/BaseHeader.vue'
import SupportFormCard from './SupportFormCard.vue'
import HeaderDesign from './HeaderDesign.vue'
import AskAi from './AskAi.vue'

const pageLabels = {
  bottimmo: {
    intro: 'support.intro-bottimmo'
  },
  remax: {
    intro: 'support.intro-remax'
  }
}

export default {
  components: {
    HelpCard,
    Support,
    BaseHeader,
    SupportFormCard,
    HeaderDesign,
    AskAi
  },
  mixins: [brandingMixin, featureMixin],
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageLabels
    }
  },
  computed: {
    topics () {
      return { CALENDAR: this.featureNames.EVENT_CALENDAR, HELPCENTER: this.featureNames.HELPCENTER }
    },
    hasDedicatedSupport () {
      return this.$features.feature(this.featureNames.APP_BRANDING).config.hasDedicatedSupport
    },
    hasSupport () {
      return this.$features.feature(this.featureNames.APP_BRANDING).config.hasSupport
    },
    activeFeaturesCount () {
      return [
        this.isFeatureActive(this.featureNames.EVENT_CALENDAR),
        this.isFeatureActive(this.featureNames.HELPCENTER)
      ].filter(Boolean).length
    }
  }
}
</script>

<template>
  <v-card outlined>
    <v-card-title class="d-flex align-items-center">
      <v-chip
        class="mr-2"
        label
        color="info"
      >
        {{ $t('labels.preview') }}
      </v-chip>
      <span>{{ $t('ai-forge.assistant-section.title') }}</span>
    </v-card-title>
    <v-card-text>
      <p>
        {{ $t('ai-forge.ask-ai-disclaimer') }}
      </p>
    </v-card-text>
    <AskAi id="o9ACC9T3HEnBg6zX989UStdaNlzVNW" />
  </v-card>
</template>

<script>
import AskAi from '@/components/AskAi.vue'

export default {
  components: {
    AskAi
  }
}
</script>
